/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import LoginForm, { ILoginFormData } from './LoginForm';
import Protected, { RenderNode } from './Protected';
import { useMountedRef } from '../hooks/useMountedRef';
import { ApiRepositoryProvider, useApiRepository } from '../hooks/useApiRepository';
import { LocaleProvider, useLocale } from '../hooks/useLocale';
import { IWidgetProps } from '../IWidgetProps';
import { Logo } from './Logo';
import { Dropdown } from 'primereact/dropdown';
import { Theme } from '../hooks/useTheme';
import { useLazyImage } from '../hooks/useLazyImage';
import { TFormLocaleKeys } from '../locales';

// language=SCSS
const outerContainer = css`
  & {
    font-size: inherit;
    padding-top: 6rem;
    padding-bottom: 6rem;

    background-color: var(--surface-a);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    min-height: 100vh;

    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > div[data-gradient] {
      display: none;
      pointer-events: none;
      position: absolute;
      width: 100%;
      height: 100%;
      backdrop-filter: blur(3px);
    }
  }
`;

// language=SCSS
const innerContainer = css`
  & {
    position: relative;
    margin-right: auto;
    margin-left: auto;
    width: calc(542rem / var(--bfs));
    border-radius: var(--border-radius);

    background: var(--surface-e);
    box-shadow: var(--shadow-raised);

    > header {
      padding: calc(40rem / var(--bfs)) calc(50rem / var(--bfs));

      > h1 {
        color: var(--primary-color-text);
        font-size: calc(24rem / var(--bfs));
        margin: 0;
      }
    }

    > main {
      padding: calc(40rem / var(--bfs)) calc(50rem / var(--bfs));
      background-color: var(--surface-a);
      border-radius: var(--border-radius);
    }
  }
`;

const langSelector = css`
  position: absolute;
  top: 2rem;
  right: 2rem;
  z-index: 1000;
`

// language=SCSS
const logoCss = css`
  & {
    margin-bottom: calc(50rem / var(--bfs));
  }
`;
type IWidgetPropsAreas = IWidgetProps & {
  areas: [
    {
      name: 'protected';
      children: Array<RenderNode>;
    }
  ];
};

export type TLicenseWarning = {
  text: string;
  type: string;
}

type LoginPageProps = {
  defaultService: string
  showService: boolean
  showLogo: boolean;
  licenseWarning?: TLicenseWarning
}

export default function Root(props: IWidgetPropsAreas) {
  return (
    <LocaleProvider value={props.props.locale}>
      <BrowserRouter>
        <ApiRepositoryProvider lib={props.props.lib}>
          {jsx(Wrapper, props)}
        </ApiRepositoryProvider>
      </BrowserRouter>
    </LocaleProvider>
  );
}

const referrerKey = 'referrer';

const buildReferrer = () => {
  const {pathname, search, hash} = window.location;
  return `?${referrerKey}=${window.encodeURIComponent(`${pathname}${search}${hash}`)}`;
}

const getReferrer = () => {
  const referrer = new URL(window.location.href).searchParams.get(referrerKey);
  return referrer ? window.decodeURIComponent(referrer) : '/';
}

const Wrapper: FC<IWidgetPropsAreas> = ({
  props: {
    loginUrl,
    noTenant = false,
    showLogo = true,
    techSupportLink
  },
  mountChildren,
  areas
}) => {
  const { $t, locale, setLocale } = useLocale();
  const mountedRef = useMountedRef();
  const { api } = useApiRepository();
  const [isLoginChecked, setIsLoginChecked] = useState(false)
  const [defaultService, setDefaultService] = useState<string>('')
  const [showService, setShowService] = useState(false)
  const [isUserLogged, setUserLogged] = useState(false)
  const [licenseWarning, setLicenseWarning] = useState<TLicenseWarning>()
  const [licenseInfo, setLicenseInfo] = useState({ licenseInfoExpiration: '', warningPeriodInDays: -Infinity })
  const checkLicenseExpiring = () => {
    if (licenseInfo.licenseInfoExpiration?.length) {
      const currentDateTimestamp = new Date().getTime()
      const licenseExpiringDateTimestamp = new Date(licenseInfo.licenseInfoExpiration).getTime()
      const daysDiff = (licenseExpiringDateTimestamp - currentDateTimestamp) / (1000 * 3600 * 24)
      if (daysDiff < licenseInfo.warningPeriodInDays) {
        setLicenseWarning({
          text: daysDiff >= -1 ?
            $t('licenseLocale.expiring', Math.ceil(daysDiff) + 1) :
            $t('licenseLocale.expired'),
          type: daysDiff >= -1 ? 'warn' : 'error'
        })
      }
    }
  }
  const checkLoginOrServerContext = () => {
    api.checkLoggedIn()
      .then(({ isUserLogged, response }) => {
        if (isUserLogged) {
          setUserLogged(true)
        }
        if (response) {
          if (mountedRef.current && 'defaultService' in response) {
            setDefaultService(response.defaultService as string)
            setShowService(response.useMultiservice as boolean)
          }
          if ('licenseInfoExpiration' in response) {
            setLicenseInfo({
              licenseInfoExpiration: response.licenseInfoExpiration as string,
              ...response.licenseInfo as object,
              warningPeriodInDays: 30
            })
          }
        }
      })
      .catch((error: Error) => console.error(error))
      .finally(() => setIsLoginChecked(true))
  }
  loginUrl = loginUrl.startsWith('/') ? loginUrl : '/' + loginUrl;
  useEffect(() => {
    checkLicenseExpiring()
  }, [locale, licenseInfo])
  useEffect(() => {
    checkLoginOrServerContext()
  }, [mountedRef])
  useEffect(() => {
    if (Boolean(defaultService) && api.unAuthListener && isUserLogged) {
      return api.unAuthListener(() => window.location.replace(`${loginUrl}${buildReferrer()}`))
    }
    return undefined;
  }, [defaultService, isUserLogged, loginUrl]);
  if ((!Boolean(defaultService) && !noTenant) || (noTenant && !isLoginChecked)) {
    return null;
  }
  return (
    <Switch>
      {isUserLogged ? (
        <Protected mountChildren={mountChildren} areas={areas} showLogoProp={showLogo} />
      ) : (
        <Route path={loginUrl}>
          {/* <div css={langSelector}>
            <Dropdown
              value={locale}
              optionLabel="title"
              options={[
                { title: 'RU', value: 'ru' },
                { title: 'EN', value: 'en' }
              ]}
              onChange={(event) => setLocale(event.value)}
            />
          </div> */}
          <LoginPage
            defaultService={defaultService}
            showService={showService}
            licenseWarning={licenseWarning}
            showLogo={showLogo}
          />
          {techSupportLink && (
            <div css={css`position: absolute; bottom: 14px; left: 20px;`}>
              <a
                href={techSupportLink}
                target="_blank"
                css={css`color: var(--text-color-secondary); text-decoration: none;`}
              >{$t('commonLocale.techSupport')}</a>
            </div>
          )}
        </Route>
      )}
      <Redirect
        push={true}
        to={{
          pathname: loginUrl,
          search: buildReferrer()
        }}
      />
    </Switch>
  );
}

const LoginPage: FC<LoginPageProps> = ({
  defaultService,
  showService,
  licenseWarning,
  showLogo
}) => {
  const { $t } = useLocale();
  const { api } = useApiRepository()
  const [credentialsHistory, setCredentialsHistory] = useState<{
    lastUsername?: string;
    lastService?: string;
    allUsedServices?: string[];
  }>();  
  useEffect(() => {
    let aborted = false;
    if (Boolean(defaultService) && api.fetchCredentialsHistory) {
      api.fetchCredentialsHistory()
        .then((res) => {
          if (!aborted) {
            setCredentialsHistory(res);
          }
        })
        .catch((error: Error) => console.error(error));
    }
    return () => {
      aborted = true;
    };
  }, []);
  const onSubmit = useCallback(
    ({username, password, service}: ILoginFormData) => {
      return api.login(username, password, service)
        .then(() => {
          setTimeout(() => window.location.assign(getReferrer()), 500);
          return null
        })
        .catch((error: { message: TFormLocaleKeys }) => {
          return $t(`formLocale.${error.message}`)
        })
    },
    [$t]
  );
  const bgImage = useLazyImage(
    useMemo(
      () => ({
        [Theme.light]: () => import('../assets/authorization-light.jpg').then((res) => res.default),
        [Theme.dark]: () => import('../assets/authorization-dark.jpg').then((res) => res.default)
      }),
      []
    )
  );
  return (
    <section css={outerContainer} style={{backgroundImage: `url(${bgImage})`}}>
      <div data-gradient={true} />
      {showLogo && <Logo css={logoCss} />}
      <article css={innerContainer}>
        <header>
          <h1>{$t('commonLocale.header')}</h1>
        </header>
        <main>
          <LoginForm
            defaultService={defaultService}
            showService={showService}
            licenseWarning={licenseWarning}
            onSubmit={onSubmit}
            lastUsedUsername={credentialsHistory?.lastUsername}
            lastUsedService={credentialsHistory?.lastService}
            allUsedServices={credentialsHistory?.allUsedServices}
          />
        </main>
      </article>
    </section>
  );
};
