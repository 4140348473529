import { useEffect, useState } from 'react';

const el = document.getElementById('theme');

export enum Theme {
  light = 'light',
  dark = 'dark'
}

export const useTheme = () => {
  const [theme, setTheme] = useState((el?.getAttribute('theme-name') || 'light') as Theme);
  useEffect(() => {
    el?.addEventListener('update', (e: any) => {
      setTheme(e.detail);
    });
  });
  return theme;
};
