/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import {CSSProperties, FC} from 'react';
import {LogoSVG} from './LogoSVG';

export const Logo: FC<{className?: string; style?: CSSProperties; secondary?: boolean; small?: boolean}> = ({
  className,
  style,
  secondary,
  small
}) => {
  return (
    <div className={className} css={$logo} style={style}>
      <LogoSVG secondary={secondary} css={[!small && $big]} />
    </div>
  );
};

// language=SCSS
const $logo = css`
  & {
    background: var(--input-bg-filled);
    box-shadow: var(--shadow-raised);
    backdrop-filter: blur(15px);
    box-sizing: content-box;
    padding: var(--logo-padding-top) var(--logo-padding-left);
    width: min-content;
    border-radius: var(--border-radius);

    > svg {
      display: block;
    }
  }
`;

const $big = css`
  & {
    width: calc(175rem / var(--bfs));
    height: calc(32rem / var(--bfs));
  }
`;
