/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import React, { FC, useEffect, useMemo, useState } from 'react';
import { FormikErrors, useFormik } from 'formik';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { useMountedRef } from '../hooks/useMountedRef';
import { useLocale } from '../hooks/useLocale';
import { genId } from '../utils/genId';
import { cx } from '../utils/cx';
import { Checkbox } from 'primereact/checkbox';
import { AutoComplete } from 'primereact/autocomplete';
import { TLicenseWarning } from './Root';
import { e2eModule } from '../__test__';

export interface ILoginFormData {
  username: string;
  password: string;
  service?: string;
}

const LoginForm: FC<{
  defaultService?: string;
  showService?: boolean;
  licenseWarning?: TLicenseWarning;
  onSubmit: (formData: ILoginFormData) => Promise<string | null>;
  lastUsedUsername?: string;
  lastUsedService?: string;
  allUsedServices?: string[];
}> = ({
  defaultService,
  showService = false,
  licenseWarning,
  onSubmit,
  lastUsedUsername,
  lastUsedService,
  allUsedServices = []
}) => {
  const { $t } = useLocale();
  const isMounted = useMountedRef();
  const [filteredServices, setFilteredServices] = useState<string[]>([]);
  const initialUser = lastUsedUsername ?? '';
  const initialService = lastUsedService ?? defaultService ?? undefined;
  const initialValues = useMemo(
    (): ILoginFormData => ({
      username: initialUser,
      password: '',
      service: initialService
    }),
    [initialUser, initialService]
  );
  const [doNotUseDefaultService, setDoNotUseDefaultService] = useState(false);
  const form = useFormik({
    initialValues,
    enableReinitialize: true,
    validateOnMount: true,
    validate: (values) => {
      const errors: FormikErrors<typeof initialValues> = {};
      if (!values.username) {
        errors.username = $t('formLocale.enterUsername');
      }
      if (!values.password) {
        errors.password = $t('formLocale.enterPassword');
      }
      return errors;
    },
    onSubmit: async (values) => {
      try {
        const err = await onSubmit(values);
        if (isMounted.current) {
          if (err) {
            form.setStatus({type: 'error', text: err});
            form.setErrors({
              username: $t('formLocale.enterUsername'),
              password: $t('formLocale.enterPassword'),
              service: $t('formLocale.enterService')
            });
          } else {
            form.setStatus({type: 'success', text: $t('formLocale.success')});
          }
        }
      } finally {
        if (isMounted.current) {
          form.setSubmitting(false);
        }
      }
    }
  });
  const status = {
    active: false,
    text: '',
    icon: '',
    color: ''
  };
  if (form.status) {
    status.active = true;
    status.text = form.status.text;
    status.icon = form.status.type === 'error' ? 'mdi-alert-circle-outline' : 'mdi-account-check-outline';
    status.color = form.status.type === 'error' ? 'var(--error-color)' : 'var(--success-color)';
  } else if (form.touched.username && form.errors.username) {
    status.active = true;
    status.text = form.errors.username;
    status.icon = 'mdi-form-textbox';
    status.color = 'var(--error-color)';
  } else if (form.touched.password && form.errors.password) {
    status.active = true;
    status.text = form.errors.password;
    status.icon = 'mdi-form-textbox-password';
    status.color = 'var(--error-color)';
  } else if (form.isSubmitting && form.isValid) {
    status.active = true;
    status.text = $t('formLocale.loading');
    status.icon = 'mdi-loading mdi-spin';
    status.color = 'var(--alternative-color)';
  } else if (licenseWarning) {
    status.active = true;
    status.text = licenseWarning.text;
    status.icon = 'mdi-alert-circle-outline'
    status.color = licenseWarning.type === 'error' ? 'var(--error-color)' : 'var(--warning-color)';
  }
  useEffect(() => {
    if (form.isValid) {
      form.setStatus('');
    }
    // eslint-disable-next-line
  }, [form.isValid]);
  const currentService = form.values.service;
  const useCustomService = currentService !== defaultService || doNotUseDefaultService;
  const [usernameId] = useState(genId);
  const [passwordId] = useState(genId);
  const [serviceId] = useState(genId);
  return (
    <form className={'p-fluid'} onSubmit={form.handleSubmit}>
      <div className={'p-field'}>
        <span className="p-float-label">
          <InputText
            id={usernameId}
            className={cx('p-inputtext-lg', form.touched.username && form.errors.username && 'p-error')}
            type={'text'}
            {...form.getFieldProps('username')}
          />
          <label htmlFor={usernameId}>
            {$t('formLocale.username')}
          </label>
        </span>
      </div>
      <div className={'p-field'}>
        <span className="p-float-label">
          <Password
            id={passwordId}
            className={cx('p-inputtext-lg', form.touched.password && form.errors.password && 'p-error')}
            feedback={false}
            {...form.getFieldProps('password')}
          />
          <label htmlFor={passwordId}>
            {$t('formLocale.password')}
          </label>
        </span>
      </div>
      {showService && (
        <div className={'p-field'}>
          <span className={'p-field-checkbox'}>
            <Checkbox
              id={serviceId}
              checked={!useCustomService}
              onChange={({checked}) => {
                if (checked) {
                  form.setFieldValue('service', defaultService);
                  setDoNotUseDefaultService(false);
                } else {
                  setDoNotUseDefaultService(true);
                }
              }}
            />
            <label htmlFor={serviceId}>
              {$t('formLocale.useDefaultService')}
            </label>
          </span>
        </div>
      )}
      {showService && (
        <div className={'p-field'} css={[smoothInsertCss, height60]} data-visible={useCustomService}>
          <span className="p-float-label">
            <AutoComplete
              id={serviceId}
              dropdown
              dropdownMode={'blank'}
              className={cx('p-inputtext-lg', form.touched.service && form.errors.service && 'p-error')}
              completeMethod={({query}) => {
                setFilteredServices(allUsedServices.filter((s) => s.includes(query)));
              }}
              suggestions={filteredServices}
              {...form.getFieldProps('service')}
            />
            <label htmlFor={passwordId}>
              {$t('formLocale.service')}
            </label>
          </span>
        </div>
      )}
      <div className={'p-field'} style={status.active ? undefined : {marginBottom: 0}}>
        <Button
          disabled={form.isSubmitting}
          type="submit"
          label={$t('formLocale.submit')}
          className={'p-button-lg'}
        />
      </div>
      <div className={'p-field'} css={[smoothInsertCss, height60]} data-visible={status.active}>
        <div css={statusCss} style={{borderColor: status.color}}>
          <i className={`mdi ${status.icon}`} style={{color: status.color}} />
          <span data-cy={e2eModule.E2E_ATTRIBUTES.infoPanel}>{status.text}</span>
        </div>
      </div>
    </form>
  );
};

// language=SCSS
const height60 = css`
  & {
    height: calc(60rem / var(--bfs));
  }
`;

// language=SCSS
const smoothInsertCss = css`
  &[data-visible='true'] {
    transform-origin: top center;
    transition: transform 100ms ease 100ms, opacity 150ms ease 100ms, height 100ms ease, margin 100ms ease;
  }
  &[data-visible='false'] {
    height: 0;
    opacity: 0;
    margin: 0;
    padding: 0;
    transform: scaleY(0.5);
    transition: transform 50ms ease, opacity 50ms ease, height 100ms ease, margin 100ms ease;
  }
`;

// language=SCSS
const statusCss = css`
  & {
    padding: 0 calc(25rem / var(--bfs));
    height: calc(60rem / var(--bfs));
    border: calc(1rem / var(--bfs)) solid transparent;
    border-radius: var(--border-radius);
    transition: border-color 200ms ease;

    display: flex;
    align-items: center;

    > i {
      font-size: calc(20rem / var(--bfs));
      margin-right: calc(10rem / var(--bfs));
      transition: color 200ms ease;
    }

    > span {
      font-size: calc(16rem / var(--bfs));
    }
  }
`;

export default LoginForm;
